import React from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";
import { InfoEmail, PromotionsEmail } from "components/Emails";
import { Hero } from "components/Hero";
import { images } from "components/HeroImages";

function ContactPage(p) {
  const title = "Contact Us | Status100 Promotions";
  const description =
    "If you have any questions or feedback, please don't hesitate to contact us.";
  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero image={images.contact}>
        <h1>Contact us</h1>
      </Hero>
      <Content>
        <List>
          <TextBlock>
            <p>
              If you have any questions or feedback, please don't hesitate to
              contact us.
            </p>
          </TextBlock>
          <TextBlock>
            <h4>General Enquiries</h4>
            <InfoEmail />
          </TextBlock>
          <TextBlock>
            <h4>Sponsorship and Press Enquiries</h4>
            <PromotionsEmail />
          </TextBlock>
        </List>
      </Content>
    </React.Fragment>
  );
}

export default ContactPage;
